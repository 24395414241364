import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.isDialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDialogVisible) = $event)),
    title: "Öğrenci Listesi",
    onClosed: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closed')))
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDialogVisible = false)),
          size: "small",
          type: "danger"
        }, {
          default: _withCtx(() => [
            _createTextVNode("İptal")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          onClick: _ctx.closeSchoolClass,
          size: "small",
          icon: _ctx.Edit
        }, {
          default: _withCtx(() => [
            _createTextVNode("Kapat")
          ]),
          _: 1
        }, 8, ["onClick", "icon"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_table, {
        data: _ctx.students,
        style: {"width":"100%"},
        size: "small"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            property: "fullName",
            label: "Ad"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.fullName.toLocaleUpperCase("tr-TR")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
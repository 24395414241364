
import { defineComponent, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import swalConfirm from "@/core/helpers/swal-confirm"
import { StudentClassAssignment } from "@/core/models/StudentClassAssignment"
import ApiService from "@/core/services/ApiService"

export default defineComponent({
  name: "class-student-closure-component",
  components: {},
  props: ["schoolClassId"],
  emits: ["closed"],
  setup(props) {
    const isDialogVisible = ref(false)

    watch(
      () => props.schoolClassId,
      newValue => {
        if (newValue) isDialogVisible.value = true
        else isDialogVisible.value = false
      }
    )

    const students = ref<StudentClassAssignment[]>([] as StudentClassAssignment[])

    watch(
      () => props.schoolClassId,
      newValue => {
        if (newValue) {
          isDialogVisible.value = true
          getClassStudentList(newValue)
        } else isDialogVisible.value = false
      }
    )

    const getClassStudentList = async schoolClassId => {
      const config = {
        params: {
          onlyAssigned: true,
        },
      }
      const { data } = await ApiService.query("student-assignment/" + schoolClassId, config)

      students.value = data
    }

    const closeSchoolClass = async () => {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("school-class/close/" + props.schoolClassId)
      }

      isDialogVisible.value = false
    }

    return {
      Edit,
      students,
      isDialogVisible,
      closeSchoolClass,
    }
  },
})

// import { FormItemRule } from "element-plus/es/components/form/src/form.type"
// import { Arrayable } from "element-plus/lib/utils"

// import { InternalRuleItem } from "async-validator"

export enum DateFormatTypes {
  TIME_SHORT, // 03:26
  TR_SHORT, // 18 Mart 2022
  TR_SHORT_NUMBER, // 18.03.2022
  TR_LONG, // 30 Nisan 2022 21:00
  TR_LONG_NUMBER, // 30.04.2022 21:00
  SHORT, // depend browser language date only
  LONG, // depend browser language date and time
}
export const dateForm = (type?: DateFormatTypes, dateString?: string | Date | undefined) => {
  let dateOptions = {} as const

  if (dateString == undefined) {
    return ""
  }

  const date = new Date(dateString ?? "")
  switch (type) {
    case DateFormatTypes.TIME_SHORT:
      dateOptions = {
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h23",
      }
      return new Intl.DateTimeFormat("default", dateOptions).format(date)
    case DateFormatTypes.TR_SHORT:
      dateOptions = {
        year: "numeric",
        month: "long",
        day: "2-digit",
      }
      return new Intl.DateTimeFormat("tr-TR", dateOptions).format(date)
    case DateFormatTypes.TR_SHORT_NUMBER:
      dateOptions = {
        year: "numeric",
        month: "numeric",
        day: "2-digit",
      }
      return new Intl.DateTimeFormat("tr-TR", dateOptions).format(date)
    case DateFormatTypes.TR_LONG:
      dateOptions = {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }
      return new Intl.DateTimeFormat("tr-TR", dateOptions).format(date)
    case DateFormatTypes.TR_LONG_NUMBER:
      dateOptions = {
        year: "numeric",
        month: "numeric",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }
      return new Intl.DateTimeFormat("tr-TR", dateOptions).format(date)
    default:
      return [{ required: true, message: "Lütfen Giriş Yapınız!", trigger: "blur" }]
  }
}

//INFO apiden gelen tarih bilgisini gün şeklinde string alabilmek için
export const formattedDate = string => {
  const date = new Date(string)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")
  return `${year}-${month}-${day}`
}

export const today = () =>
  new Date().toLocaleString("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }) as unknown as Date

export const firstDayofMonth = () => {
  const now = new Date()
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
  return firstDayOfMonth.toLocaleString("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }) as unknown as Date
}

export const today0000 = () =>
  new Date(new Date().setHours(0, 0, 0, 0)).toLocaleString("tr-TR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }) as unknown as Date

export const today2359 = () =>
  new Date(new Date().setHours(23, 59, 59, 999)).toLocaleString("tr-TR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }) as unknown as Date

export const lastDayofMonth = () => {
  const now = new Date()
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)
  return lastDayOfMonth.toLocaleString("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }) as unknown as Date
}
